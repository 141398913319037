import React from 'react';
import styled from 'styled-components';
import {Tabs, message} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import withPageEntry from '../../withPageEntry';

function RequestPage(props) {
  return (
    <Wrapper>
      <div className="container">
        <h1 className="title">忘記密碼(1/3)</h1>
        <GenericForm
          instance={null}
          schema={FORGOT_PWD_JSON.schema}
          uiSchema={FORGOT_PWD_JSON.uiSchema}
          errorSchema={FORGOT_PWD_JSON.errorSchema}
          onSubmit={onSubmit}
        />
      </div>
    </Wrapper>
  );
}

const FORGOT_PWD_JSON = {
  schema: {
    title: '',
    type: 'object',
    required: ['username', 'phone'],
    properties: {
      username: {
        type: 'string',
        title: '帳號',
        pattern: '',
      },
      phone: {
        type: 'string',
        title: '手機號碼',
        pattern: '^09[0-9]{8}$',
      },
    },
  },
  uiSchema: {},
  errorSchema: {
    username: {
      pattern: '帳號請輸入您的身分證字號，第一個字母為大寫。',
      required: '帳號為必填欄位',
    },
    phone: {
      pattern: '請輸入正確的手機格式，以09為開頭',
      required: '手機號碼為必填欄位',
    },
  },
};

const onSubmit = async (formData) => {
  const {username, phone} = formData;

  AppActions.setLoading(true);

  try {
    const resp = await AppActionsEx.smsForgotPasswordRequest({username, phone});

    const {state, expired_timestamp} = resp;
    const nextPath = `/forgot-password/confirm/?state=${state}&expired=${expired_timestamp}&username=${username}&phone=${phone}`;

    AppActions.navigate(nextPath);
  } catch (err) {
    console.warn(err);
    message.error('發生錯誤! 請稍後再試。');
  } finally {
    AppActions.setLoading(false);
  }
};

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .container {
    max-width: 600px;
    width: 100%;
    min-height: 80vh;

    margin: 0 auto;
    padding: 60px 20px;

    & > .title {
      font-weight: 500;
      color: var(--primaryColor);
    }
  }

  & .text {
    margin-bottom: 10px;
    line-height: 1.85rem;
    font-size: 0.95rem;

    & .link {
      margin-right: 5px;
      margin-left: 5px;
    }

    .highlight {
      margin-right: 5px;
      margin-left: 5px;
      font-weight: 600;
      color: var(--secondColor);
    }
  }
`;

export default withPageEntry(RequestPage);
